@import '_variables';


@media only screen and (min-width : 2300px) {
    .main-nav{
        .main-nav-content{
            width: calc(17% + #{$marge});
        }
    }

}

@media only screen and (min-width : 1950px) {
    .recherche-bien{
        background-size: 100% 156px;
    }
}



@media only screen and (max-width : 1400px) {
    .main-nav{
        .main-nav-content{
            width: calc(43% + calc((100% - 1280px) / 2));
            padding-top: 45px;
        }
        .adresse{
            bottom: 20px;
        }
    }
}

@media only screen and (min-width : 1500px) {
    .blog-slider .swiper-button-next{
        right: 42.2%;
    }
    .blog-slider .swiper-button-prev{
        left: 42.2%;
    }
}

@media only screen and (min-width : 1300px) {
    // .recherche-bien,
    // .premarket-step1{
    //     .metro, .tram{
    //         max-width: $wrap;
    //     }
    //     .station{
    //         label{
    //             margin-right: 28px;

    //             &:after{
    //                 width: 45px;
    //             }
    //         }
    //     }
    //     .station{
    //         .logo-map{
    //             left: 51.3%;
    //         }
    //         .projetim-here{
    //             left: 721px;
    //         }
    //     }
    //     .station.ligne-t2 p{
    //         margin-right: 339px;
    //     }
    //     .station.ligne-b p{
    //         margin-right: 67px;
    //     }
    // }
}
@media only screen and (max-height : 767px) {
    .main-nav .links li:nth-child(5),
    .main-nav .links li:nth-child(12){
        margin-bottom: 20px;
    }
}

@media only screen and (max-width : 1280px) {
    .menu-burger{
        right: 30px;
    }
    header{
        .flex-item:nth-child(1){
            padding-left: 30px;
        }
        .flex-item:nth-child(3){
            padding-right: 30px;
        }
    }
    .node-type-contact{
        .formulaire-contact::after{
            left: 79%;
        }
    }
    .bloc-slider .swiper-container .swiper-slide .content{
        padding: 0 100px;
    }
    .sitemap-bloc{
        padding-right: 15px;
        padding-left: 15px;
    }
    .view-display-id-block_3,
    .block-projetim-selections{
        padding: 0 30px;
    }
    .chart_investisseur{
        .flex-item:nth-child(2){
            padding-left: 10%;
        }
        .flex-item:nth-child(3){
            padding-right: 10%;
        }
    }
}
@media only screen and (max-width : 1120px) {
		.chart_investisseur{
        .flex-item:nth-child(2){
            padding-left: 0%;
        }
        .flex-item:nth-child(3){
            padding-right: 0%;
        }
    }
}



@media only screen and (max-width : 1024px) {
    .main-nav{
        .main-nav-content{
            width: 100%;
        }
        // .adresse{
        //     bottom: 58px;
        //     line-height: 1.4rem;
        // }
        // .mentions{
        //     bottom: 30px;
        // }
    }
    .detail-top {
        .content-flex{
            padding: 0;
        }
        .galerie{
            width: 75%;
        }
    }
    .link-action,
    .detail-top .gallery-thumbs{
        margin-top: 92px;
    }
    .bien-info{
        padding-top: 130px;
        margin-top: -65px;

        .flex-invest{
            ul{
                flex: 0 47%;
            }
        }
        ul{
            flex: 0 31%;
            margin-bottom: 60px;
        }
    }
    .view-biens{
        .flex-item{
            flex: 0 31%;
        }
        //padding-left: 10px;
        //padding-right: 10px;
    }
    .type-recherche{
        .flex{
            justify-content: space-around;
            padding: 0 10px;
        }
    }
    .souhait-recherche{
        label{
            span{
                font-size: 2.2rem;
            }
        }
    }
    // .main-nav .main-nav-content{
    //     padding-top: 75px;
    // }
    .main-nav .adresse{
        //position: relative;
        margin-top: 20px;
        //bottom: 0;
        //left: 0;
    }
    .main-nav .mentions{
        position: relative;
        bottom: 0;
        left: 0;
        margin-top: 5px;
        margin-bottom: 10px;
    }
    .chart_investisseur{
        .flex-item:nth-child(2){
            padding-left: 0%;
        }
        .flex-item:nth-child(3){
            padding-right: 0%;
        }
    }
    .investisseur{
        .bloc-2-col{
            .flex-item:nth-child(1){
                padding-left: 0%;
            }
            .flex-item:nth-child(2){
                padding-right: 0%;
            }
        }
    }
    .blog-5-last-post img{
        flex: 0 55px;
        padding: 10px;
        margin-right: 14px;
        width: 55px;
        height: 55px;
    }
    .blog-5-last-post div {
        flex: 0 calc(100% - 77px);
    }
    .view-galerie{
        .titre2{
            font-size: 2.4rem;
            padding: 0 10px;
            line-height: 2.6rem;
        }
    }
    .swiper-texte-icone .swiper-slide{
        padding: 40px 100px;
    }
    .swiper-texte-icone .swiper-button-next,
    .swiper-texte-icone .swiper-button-prev{
        display: none;
    }
}


@media only screen and (max-width : 1000px) {
    .recherche-bien{
        //background-size: 100% 143px;

        .menu-recherche,
        .filtre,
        .metro,
        .tram{
            display: none !important;
        }
        .bloc-recherche{
            height: auto;
            padding-top: 15px;
        }
        .titre2{
        	padding-bottom: 20px;
        }
    }
    .blog-slider,
    .page-node-383 .blog-slider{
        padding-right: 0;
        padding-left: 0;
        width: 100%;
        overflow: hidden;

        .swiper-container,
        .wrap{
            width: 250%;
            max-width: 250%;
            left: -75%;

            .content{
                padding: 15px;
            }
        }
    }
    .view-biens{
        i{
            top: 88%;
        }
    }
    .recherche-bien .titre1{
        padding-top: 60px;
        padding-bottom: 20px;
    }
    .reseaux,
    .instafeed,
    footer,
    .info-projetim{
        display: none;
    }
    .bloc-slider .swiper-container{
        margin-bottom: 0;
    }
    .block-push li a{
        font-size: 1.4rem;

        .titre{
            font-size: 2.7rem;
        }
    }
    .bloc-slider .swiper-container .swiper-slide .content{
        padding: 0 50px;

        .titre4{
            font-size: 2.4rem;
        }
    }
    .main-nav{
        .main-nav-content{
            width: 100%;
        }
        .mentions{
            bottom: 100px;
        }
        .adresse{
            bottom: 20px;
        }
    }
    .blog-liste .grid-item, .blog-liste .grid-sizer{
        width: 50%;
    }
    .blog-content .categorie{
        float: none;
        display: block;
    }
    .btn-share{
        margin-bottom: 10px;
        float: none;
        display: block;

        li{
            margin: 0 1px;
        }
    }
    .node-type-contact{
        .formulaire-contact{
            &:after{
                top: 198px;
            }
        }
        .bloc-info{
            //padding: 118px 0  0;
        }
        .webform-client-form{
            //padding: 40px 0 20px 80px;

            fieldset{
                width: 100%;
            }
        }
    }
    .detail-top{
        .gallery-thumbs{
            display: none;
        }
        .galerie{
            margin: 0 40px 0 0;
        }
    }
    .bien-info{
        margin-top: 25px;
        padding-top: 50px;
    }
    .avis-projetim{
        .col:first-child{
            padding-right: 60px;

            img{
                margin: 15px 0 0;
            }
        }
        .col:last-child{
            padding-right: 20px;
        }
    }
    .map-bien{
        margin-top: 30px;

        .map-info-bien{
            width: 100%;
            left: 0;
            top: 0;
            transform: translate(0, 0);
            position: relative;
            text-align: center;

            ul{
                display: inline-block;
                justify-content: space-between;
                display: flex;
                flex-wrap: wrap;
            }

            li{
                display: inline-block;
                list-style-type: none;
                flex: 0 24%;
                font-size: 1.2rem
            }
        }
    }
    .action-choices,
    .action-choices-bien-vendu{
        padding-left: 0;
        padding-right: 0;

        .titre3{
            font-size: 2.5rem;
        }
        .col:first-child{
            margin-bottom: 0;
        }
        .action-contact{
            background: linear-gradient(to bottom, transparent 0%, transparent calc(100% - 80px), $fdgris calc(100% - 80px), $fdgris 100%);
            padding-bottom: 120px;
        }
        form.webform-client-form-28,
        form[name="visit"]{
            padding-right: 15px;
            padding-left: 15px;
            background-image: none;
            margin-bottom: 0;
            padding-bottom: 0;
        }
        .tableau-visite{
            margin-left: 15px;
            margin-right: 15px;
            width: calc(100% - 30px);

            .btnNext,
            .btnPrev{
                top: 5px;
                font-size: 2.7rem;
            }
            ul{
                margin-right: 3px;
                margin-left: 3px;
            }
        }
    }
    .action-choices-bien-vendu{
        .action-contact{
            padding-bottom: 50px;
            background-image: none;

            &:after{
                display: none;
            }
        }
    }
    .filtre-galerie .flex .filter-bien{
        margin-left: 0;
        margin-top: 15px;
    }
    .calculateur-epargne{
        .titre4{
            font-size: 2.4rem;
        }
    }
    .chart_investisseur{
        background-position: calc(50% + 21px) 37px;

        .piechart{
            margin-left: 20px;
        }
        .piechart-empty{
            left: 34px;
        }
    }
    .detail-top{
        background: linear-gradient(to bottom, #ecebe7 0%, #ecebe7 60%, #ffffff 60%, #ffffff 100%);
        p{
            width: 100%;
        }
        h1{
            padding-top: 30px;
        }
        .gallery-thumbs{
            display: none;
        }
        .galerie{
            width: 96%;
            margin: 0 0 20px;
        }
    }
    .link-action{
        margin-top: 15px;

        li{
            //display: inline-block;
            //margin: 0 3px;
        }
    }
    .bien-info{
        .flex-invest{
            ul{
                flex: 0 47%;
            }
        }
        ul{
            flex: 0 47%;
            margin-bottom: 60px;
        }
    }
    .action-choices{
        height: auto;

        .col{
            position: relative;
            width: 100%;
        }
        .left,
        .right{
            width: 100%;
            float: none;
        }
        textarea{
            margin-bottom: 10px;
        }
        .small{
            display: none;
        }
        .tableau-visite{
            .flex-item-c{
                .date-jour,
                .date-date{
                    display: none;
                }
                .date-mob{
                    display: inline-block;
                }
            }
            .flex{
                padding: 0 15px;
            }
            li.dispo:not(.date){
                font-size: 1rem;
                letter-spacing: -0.7px;
            }
            .separation{
                margin-bottom: 10px;
            }
            .barre-top{
                top: 38px;
            }
            .btnNext,
            .btnPrev{
                font-size: 2rem;
                top: 0;
            }
            .btnPrev{
                left: -10px;
            }
            .btnNext{
                right: -10px;
            }
        }
        .js-date{
            font-size: 2.1rem;
        }
    }
    .view-galerie{
        .titre2{
            font-size: 1.8rem;
            padding: 0 10px;
            line-height: 1.6rem;
        }
        .overlay{
            ul,
            .barre-horitzontale{
                display: none;
            }
        }
    }
    .souhait-recherche{
        label{
            i{
                display: block;
                margin-right: 0;
                margin-top: 4px;
            }
            span{
                font-size: 2rem;
            }
        }
    }
    .recherche-bien .more-filtre{
        bottom: 7px;
    }
    .form-no-result-bloc1{
        form{
            padding-left: 10px;
            padding-right: 10px;
        }
    }
    .vendu{
        top: 2px;
    }
    .node-type-coup-de-coeur .blog-slider .wrap{
        width: auto;
        max-width: auto;
        left: 0;
        padding-left: 10px;
        padding-right: 10px;
    }
    .content-page-basic{
        .flex-item{
            flex: 0 100%;
        }
    }
    .prise-rendez-vous-invest{
        form{
            .row:nth-of-type(1){
                .left{
                    width: 49.6%;
                    float: left;
                }
                .right{
                    width: 49.6%;
                    float: right;
                }
            }
            .small{
                display: block;
            }
        }
    }
    .equipe ul li{
        flex: 0 50%;
    }
    .swiper-texte-icone .swiper-slide{
        padding: 40px 50px;
    }
    .premarket-step1 {
        display: none;
    }
    .premarket-step2{
        background-image: none;

        .step{
            display: none;
        }
    }
    .premarket-step3{
        .step{
            display: none;
        }
    }
    .premarket-etat .flex-item:nth-child(1) p{
        font-size: 3rem;
    }
}

@media only screen and (max-width : 1000px)  and (min-width : 768px) {
    .blog-5-last-post{
        padding: 15px;

        .categorie{
            display: none;
        }
        img{
            padding: 6px;
            width: 55px;
            height: 55px;
            margin-right: 15px;
        }
    }
    .bloc-social p span{
        font-size: 3.4rem;
    }
    .view-biens .flex-item{
        max-width: calc((100% - 20px) / 2);
        flex: calc((100% - 20px) / 2);

        &:nth-child(3n){
            margin-right: 20px;
        }
        &:nth-child(2n){
            margin-right: 0px;
        }
    }
    .filter-bien .flex-item{
        padding: 0 15px;
    }
    .filter-bien{
        input[type="text"]{
            width: 70%;
        }
    }
}

@media only screen and (min-width : 768px) {
    header{
        &.sticky{
            height: 77px;

            .logo2{
                transform: scale(0.65) translateY(-53px);
            }
            .logo{
                transform: scale(0.6);
            }
            .flex-item{
                &:nth-child(1){
                    padding-top: 8px;
                }
                &:nth-child(3){
                    padding-top: 8px;
                }
            }
        }
    }
    .menu-burger{
        &.sticky{
            transform: translateY(-118%);
        }
    }
    .main-nav .links li:nth-child(12){
        display: inline-block;
    }
}


@media only screen and (max-width : 767px) {
    .titre2,
    .coup-de-coeur h2{
        font-size: 2.7rem;
    }
    .titre3, .equipe h2 {
        font-size: 2.5rem;
        line-height: 3rem;
    }
    .site{
        padding-top: 70px;
    }
    .fd_titre{
    	padding: 61px 15px 21px;
  	}
    header{
        height: 70px;

        .flex-item{
            height: 100px;
        }
        .flex-item:nth-child(1){
            padding-left: 15px;
            padding-top: 22px;
        }
        .flex-item:nth-child(3){
            padding-right: 15px;

            .selection{
                display: none;
            }
        }
    }
    main section, main article{
        padding: 0 15px;
    }
    main{
    section, article{
        p{
					font-size: 1.4rem;
        }
      }
    }
    .exergue{
		  font-size: 1.8rem;
		  line-height: 2.6rem;
		  margin-bottom: 0px;

		}
		.section-les-actus-immobilieres{
			.separateur{
				height: 30px;
			}
		}
    .titre1{
        font-size: 4rem;
        text-shadow: 0 0 5px rgba($noir, 0.3);
    }
    .menu-burger{
        right: 15px;
        top: 50px;
        height: 43px;
    		width: 43px;
    		.menu-burger-slice{
    			&:nth-child(1){
            top: 15px;
	        }
	        &:nth-child(2),
	        &:nth-child(3){
	            top: 21px;
	        }
	        &:nth-child(4){
	            top: 27px;
	        }
    		}
    		&.active{

        	.menu-burger-slice{
        		&:nth-child(2) {
              transform: rotate(45deg);
              left: 12px;
            }
            &:nth-child(3) {
              transform: rotate(-45deg);
              left: 12px;
            }
        	}
        }
    }
    .logo{
        width: 95px;
        height: auto;
    }
    .logo2{
        width: 90px;
        height: auto;
    }
    .main-nav{
        .main-nav-content{
            width: 100%;
            padding-left: 30px;
            padding-right: 30px;
            padding-top: 30px;
            min-width: 320px;
        }
        .links{
            li{
                margin-bottom: 5px;

                a{
                    font-size: 1.8rem;
                }
                &:nth-child(n+6){
                    margin-bottom: 5px;

                    a{
                        font-weight: 700;
                        font-size: 1.8rem;
                    }
                }
                &:nth-child(5){
                    margin-bottom: 30px;
                }
                .menu-selections{
                    display: inline-block;
                }
            }
        }
        .adresse,
        .mentions{
            left: 30px;
            //position: relative;
            //bottom: 0;
            line-height: 1.7rem;
            font-size: 1rem;
        }
        .reseaux-menu{
            //position: relative;
            //bottom: 0;
            //margin-top: 100px;
        }
    }
    .recherche-bien{
    	.titre2{
    		padding-bottom: 15px;
    	}
    }
    .reseaux-menu{
        margin-bottom: 30px;
        margin-top: 30px;
        left: 30px;
        bottom: 50px;
        p{
            margin-bottom: 5px;
        }
        a{
            width: 36px;
            padding: 6px 0;
            border: 2px solid $blanc;

            i{
                font-size: 1.8rem;
            }
        }
    }
    .bloc-recherche h2{
        font-size: 1.45rem;
    }
    .souhait-recherche{
        padding-left: 15px;
        padding-right: 15px;

        label{
            text-align: center;
            width: calc((100% - 10px) / 3);
            font-size: 1.8rem;

            i{
                margin-right: 0;
                font-size: 4rem;
                margin-bottom: 10px;
            }
            span{
                font-size: 1.3rem;
                line-height: 15px;
            }
        }
    }
    .type-recherche{
        label{
            width: calc((100% - 25px) / 3);
            margin-bottom: 10px;

            i{
                font-size: 3rem;
            }
        }
    }
    .affiner-recherche{
        margin: 20px 20px 0;

        p{
            flex: 0 100%;
            text-align: center;
            margin-bottom: 10px;
        }
        input{
            width: 32%;
            margin-bottom: 5px;
        }
    }
    .filter-bien .views-show-only-available{
        margin-left: 0;
    }
    .node-type-liste-biens .view-biens.wrap{
        padding: 0 15px;
    }
    .view-biens p {
        text-align: center;
        margin-bottom: 6px;
    }
    .vendre-bien{
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 5px;

        .titre4{
            font-size: 1.65rem;
        }
        h2 i{
            margin: 0 1px;
            font-size: 4rem;

            &:after {
                left: 14px;
                top: 7px;
                font-size: 3rem;
            }
        }
        p:not(.required-field) span{
            width: 110px;
        }
        .form-contact-vendre{
            width: 100%;
        }
        input,
        select{
            width: 100%;
        }
        .form-contact-vendre input:not([type="submit"]){
            width: 100%;
        }
        input[type="submit"]{
            margin-top: 10px;
        }
        .message-container{
            padding-bottom: 20px;

            &:empty{
                padding-bottom: 0;
            }
        }
        .form-contact-vendre{
            padding-bottom: 20px;
        }
    }
    .block-push li{
        background-image: none;
    }
    .block-push li.investir,
    .block-push li.projet{
        display: none;
    }
    .bloc-slider .swiper-container{
        height: auto;

        .swiper-slide{
            height: auto;

            a{
                flex-wrap: wrap;
                height: auto;
            }
            .fd-slider,
            .content{
                flex: 0 100%;
                height: 325px;
                margin-bottom: 0;
                padding: 0;
            }
            .content{
                height: auto;
                padding: 95px 15px 50px;

                .titre5{
                    margin-top: 0;
                }
            }
        }
    }
    .bloc-slider .logo-projetim{
        top: 279px;
        transform: translate(-50%, 0);
    }
    .node-type-a-propos-de-nous{
        .texte ul li{
            flex: 100%;
            margin-bottom: 30px;
            margin-right: 0;
        }
        .etape-projet{
            .row{
                .num{
                    left: 42.2%;
                }
                .left,
                .right{
                    padding: 30px 15px;
                }
            }
            h2{
                font-size: 1.85rem;
            }
        }
    }
    .node-type-contact{
        .formulaire-contact{
            background: linear-gradient(to bottom, #00ABC5 0%, #00ABC5 35%, #3fc0d5 35%, #3fc0d5 100%);

            &:after{
                display: none;
            }
        }
        .webform-client-form:after{
            display: none;
        }
        .bloc-info{
            flex: 0 100%;
            padding: 0 0 50px 0;

            fieldset{
                width: 100%;
            }
        }
        #webform-ajax-wrapper-72{
            flex: 0 100%;
        }
        .carte .coordonnees-map{
            position: relative;
            left: 0;
            top: 0;
            width: 100%;
        }
    }
    .page-node-383 .blog-slider{
        .content h3 a{
            font-size: 2rem;
        }
        .content p{
            font-size: 1rem;
        }
        .swiper-button-next{
            width: 20px;
            height: 23px;
            bottom: 23px;

            &:before{
                font-size: 1.7rem;
            }
        }
        .swiper-button-prev{
            width: 20px;
            height: 23px;
            bottom: 23px;

            &:before{
                font-size: 1.7rem;
            }
        }
    }
    .blog-content{
        flex: 100%;
    }
    .blog-aside{
        flex: 100%;
        margin-left: 0;
    }
    .citation{
        margin-left: 0px;
    }
    form input,
    form select,
    form textarea,
    form button{
        font-size: 1.3rem;
    }
    .view-display-id-block_3, .block-projetim-selections{
        padding: 0 15px;
    }
    .view-biens{
        .flex{
            //padding: 0 15px;
        }
        .flex-item{
            flex: 100%;
            width: 100%;
            max-width: 100%;
            margin-right: 0;
        }
        i{
            top: 76%;
        }
    }
    .filter-bien{
        padding-left: 15px;
        padding-right: 15px;

        .flex-item{
            flex: 0 100%;
            margin-bottom: 10px;
            text-align: left;
            border: 0;
        }
    }
    .blog-liste .grid-item,
    .blog-liste .grid-sizer{
        width: 100%;
    }
    .bien-info{
        margin-top: 30px;
        padding-top: 30px;
        padding-bottom: 30px;

        .flex-invest{
            ul{
                flex: 0 100%;
            }
        }
        ul{
            flex: 0 100%;
            margin-bottom: 50px;

            &:last-child{
                margin-bottom: 100px;
            }
        }
    }
    .exclu{
        top: 12px;
    }
    .detail-top{
        background: linear-gradient(to bottom, #ecebe7 0%, #ecebe7 70%, #ffffff 70%, #ffffff 100%);
    }
    .avis-projetim{
        .col{
            width: 100%;
            text-align: center;

            &:nth-child(1){
                text-align: center;
                padding-right: 0;
                background-image: none;

                img{
                    margin: 15px auto;
                }
                h2{
                    width: 100%;
                }
            }
            &:last-child{
                padding: 0;
            }
        }
    }
    .map-bien .map-info-bien{
        li{
            flex: 0 49%;
            font-size: 1rem;
        }
    }
    .blog-5-last-post{
        padding: 20px 0 0;

        img{
            width: 70px;
            height: 70px;
        }
    }
    .investisseur{
        .bloc-2-col{
        	padding-top: 30px;
        	padding-bottom: 20px;
            .flex-item{
                flex: 0 100%;

                &:nth-child(1){
                    text-align: left;
                    margin-bottom: 30px;
                }
            }
        }
    }
    .camembert{
        display: none;
    }
    .chart_investisseur{
        background-image: none;
        padding-top: 0px;
        margin-bottom: 0px;

        .flex-item{
            flex: 0 100%;
        }
        form{
        	margin-bottom: 20px;
        }
        .remplissez-mobile{
        	display: block;
        	width: 100%;
        	margin-top: 15px;
        }
        .remplissez-desktop{
        	display: none;
        }
        input{
            font-size: 1.2rem;
        }
        label{
            font-size: 1.3rem;
        }
        .link-action{
            margin-top: 275px;
            li{
            	display: inline-block;
            }

        }
        .piechart, .piechart-empty{
          	position: absolute;
          	left: 50%;
          	transform: translateX(-50%);
          	margin-left: 0px;
          	margin-top: 0px;
        }
        .piechart-empty{
        	top: 14px;
        }

        .flex-item:nth-child(3){
            //height: 430px;
        }

    }

    .resultat-calculateur{
        p{
            font-size: 1.4rem;
        }
        .titre3{
            font-size: 2.5rem;
            line-height: 2.8rem;
        }
        .nb-result{
            font-size: 3.5rem;
            width: 70px;
            padding: 21px 0;
        }
    }
    .view-galerie{
        .grid-item,
        .grid1,
        .grid2{
            width: 100% !important;
        }
        .titre2{
            font-size: 1.8rem;
            padding: 0 10px;
            line-height: 1.6rem;
        }
        .overlay{
            ul,
            .barre-horitzontale{
                display: block;
            }
        }
    }
    .prise-rendez-vous-invest{
	    	.exergue{
	    		text-align: center;
	    	}
    		padding-bottom: 30px;
        .flex-item{
            flex: 0 100%;
            padding: 0 15px;
            margin-bottom: 20px;

            &:nth-child(1){
                text-align: left;
            }
        }
        form input:not([type="submit"]){
            width: 100%;
        }
        .small{
            margin-bottom: 20px;
        }
        form::after{
            display: none;
        }
    }
    .equipe ul li{
        flex: 0 100%;
    }
    .swiper-texte-icone .swiper-slide{
        padding-left: 0px;
        padding-right: 0px;
        height: 350px;
        font-size: 1.8rem;
    }
    .detail-top .descriptif{
        padding: 0;
    }
    .form-no-result-bloc1{
        form{
            fieldset,
            fieldset.webform-component--groupe2{
                width: 100%;
                margin-bottom: 15px;
            }
        }
    }
    .premarket-step2{
        .js-notempty{
            p{
                display: flex;
            }
        }
    }
    .premarket-step3{
        .form-contact-vendre{
            padding: 0 15px;

            &:after{
                display: none;
            }
        }
    }

    .premarket-etat{
        .flex{
            flex-direction: column;

            .flex-item{
                &:nth-child(2){
                    display: none;
                }
                &:nth-child(3){
                    padding: 50px 20px 0;
                    text-align: center;
                }
            }
            .nombre{
                width: 200px;
                height: 200px;
                font-size: 2rem;
                padding-top: 53px;

                span{
                    font-size: 6rem;
                }
            }
        }
    }
    .link-action{
        display: flex;
        justify-content: center;

        li{
            margin: 0 10px;
        }
    }
    .node-type-espace-investisseur{
        .schedule{
            text-align: center;
        }
        .schedule .picto-calendar{
            display: none;
        }
        .schedule .form{
            padding: 10px;
        }
        .schedule.schedule-calc-investissor .rappel_chart .infos{
            width: 100%;
            margin: 0;
            text-align: left;
        }
        .schedule.schedule-calc-investissor .rappel_chart .infos p span.label{
            width: 66%;
            float: none;
            display: table-cell;
        }
        .schedule.schedule-calc-investissor .rappel_chart .infos p span.prix{
            width: 33%;
            float: none;
            display: table-cell;
            vertical-align: middle;
        }
        .schedule.schedule-calc-investissor .rappel_chart .img_chart{
            float: none;
            margin-top: 20px;

            img{
                width: 100%;
                height: auto;
            }
        }
    }
    .node-type-a-propos-de-nous .vous-souhaitez{
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media only screen and (max-width : 767px) {
    .btn{
        max-width: 330px;
    }
}

@media only screen and (max-width : 350px) {
	.main-nav-content{
		p.adresse{
			display: none;
		}
		.reseaux-menu{
			bottom: 0px;
		}
	}
}
